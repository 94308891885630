import React from "react";
import "./admin-link-store.css";

function AdminLinkStore({ socialMediaLinks, setSocialMediaLinks }) {
  const socialMediaNames = [
    "phone",
    "email",
    "whatsapp",
    "instagram",
    "facebook",
    "linkedin",
    "website",
    "googleMap",
    "behance",
    "medium",
    "spotify",
    "youtube",
    "vimeo",
  ];

  const handleAddRow = () => {
    // Check if the last link's name is not empty before adding a new row
    if (
      socialMediaLinks.length === 0 ||
      socialMediaLinks[socialMediaLinks.length - 1].name !== ""
    ) {
      const newLink = {
        name: "", // Default name
        link: "",
        status: true,
        count: 0,
      };
      if (newLink.name === "" && newLink.link === "") {
        setSocialMediaLinks([...socialMediaLinks, newLink]);
      }
    }
  };

  const handleRemoveRow = (index) => {
    const updatedLinks = socialMediaLinks.filter((_, i) => i !== index);
    setSocialMediaLinks(updatedLinks);
  };

  const handleLinkNameChange = (index, newName) => {
    const updatedLinks = [...socialMediaLinks];
    if(newName !== "") {
      updatedLinks[index].name = newName;

      setSocialMediaLinks(updatedLinks);
    }
  };

  const handleLinkChange = (index, newLink) => {
    const updatedLinks = [...socialMediaLinks];
    if(newLink !== "") {
      updatedLinks[index].link = newLink;
    setSocialMediaLinks(updatedLinks);
    }
  };

  return (
    <div className="admin-link-store">
      <div className="link-store-header">
        <h1>Link Store</h1>
      </div>
      {socialMediaLinks.map((link, index) => (
        <div className="link-store-content" key={index}>
          <div className="link-store-content-left">
            <select
              value={link.name}
              onChange={(e) => handleLinkNameChange(index, e.target.value)}
            >
              <option value="">Select Store</option>
              {socialMediaNames.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="link-store-content-right">
            <input
              type="text"
              value={link.link}
              onChange={(e) => handleLinkChange(index, e.target.value)}
            />
            {socialMediaLinks.length > 1 && (
              <button
                className="minus-button"
                onClick={() => handleRemoveRow(index)}
              >
                -
              </button>
            )}
          </div>
        </div>
      ))}
      <button className="add-link-button" onClick={handleAddRow}>
        +
      </button>
    </div>
  );
}

export default AdminLinkStore;
