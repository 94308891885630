import React, { useEffect } from 'react';
import './admin-otp-verify.css';
import { useState } from 'react';
import axios from 'axios';
import SERVER_URL from '../../../config/serverURL';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
function AdminOTPVerify() {
const [OTP, setOTP] = useState('');
const [open, setOpen] = useState(false);
const { email } = useParams();
  const navigate = useNavigate();
  useEffect(()=>{
 if(!email){
    navigate('/admin/login');
 }
  },[email, navigate]);
function handleSendOTP(){
  toast.info("Sending OTP ....");
    axios.post(SERVER_URL+'/admin/api/forgotPassword', {email}).then(res=>{
        if(res.status === 200){
            toast.success("OTP Sent Successfully");
        setOpen(true);
        }
    }).catch((err)=>{
        navigate('/admin/login');
        console.log(err);
    })
}
function handleSubmit(e){
    e.preventDefault();
    axios.post(SERVER_URL+'/admin/api/verifyOTP', {email,otp: OTP}).then(res=>{
        if(res.status === 200){
          localStorage.setItem('adminToken',res.data.token);
        navigate('/admin/reset-password');
        }
    }).catch((err)=>{
        console.log(err);
    })
}

  return (
    <>
      <div className="admin-login">
        <div className="admin-login__container">
          <div className="admin-login__container__header">
            <h1>Verify OTP</h1>
          </div>
          <div className="admin-login__container__body">
            {!open ? <>
            
          <div className="admin-login__container__body__form-group">
                <button type="submit" onClick={handleSendOTP}>Send OTP</button>
              </div>
            </>:<>
            
              <div className="admin-login__container__body__form-group">
                <label htmlFor="otp">Enter Your OTP</label>
                <input type='number' id="otp" placeholder='Enter Your OTP' onChange={(e)=>setOTP(e.target.value)}/>
              </div>
              <div className="admin-login__container__body__form-group">
                <button type="submit" onClick={handleSubmit}>Submit</button>
              </div>
            </>}
             

          </div>
        </div>
      </div>
    </>
  );
}

export default  AdminOTPVerify;
