import React, { useEffect, useState } from "react";
import "./admin-dashboard.css";
import AdminProfileCreation from "../AdminProfile/AdminProfileCreation";
import AdminLinkStore from "../AdminLinkStore/AdminLinkStore";
import AdminAllUsers from "../AdminAllUsers/AdminAllUsers";
import axios from "axios";
import SERVER_URL from "./../../../config/serverURL";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../Loading";

function AdminDashboard() {
  const [profile, setProfile] = useState(false);
  const [linkStore, setLinkStore] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [userData, setUserData] = useState({
    name: "",
    username: "",
    email: "",
    mobileNo: 0,
    phoneNumber: 0,
    companyName: "",
    bio: "",
    designation: "",
  });
  const [logo, setLogo] = useState(null);
  const [cover, setCover] = useState(null);
  const [ProfileImage, setProfileImage] = useState(null);
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [isActiveChanges, setIsActiveChanges] = useState(false);
  const [user, setUser] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  useEffect(() => {
    if(!localStorage.getItem("adminToken")){
      navigate("/admin/login")
    }
    axios
      .get(SERVER_URL + "/admin/api/protected", {
        headers: { "x-access-token": localStorage.getItem("adminToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          setUser(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        navigate("/admin/login");
      });
  }, [navigate, user]);
  useEffect(() => {
    if (user) {
      axios
        .get(SERVER_URL + "/admin/api/getUsers?page=" + pageCount, {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        })
        .then((res) => {
          setAllUsers(res.data);
        })
        .catch((error) => {
          console.error("Error fetching user list:", error);
        });
    }
  }, [pageCount, user, isActiveChanges]);

  //profile creation submit
  function handleProfileCreationSubmit() {
    if (!userData.username) {
      return toast.error("Username is required");
    }
    if (!alreadyExist) {
      if (!logo) {
        return toast.error("Logo is required");
      }
      if (!cover) {
        return toast.error("Cover Image is required");
      }
      if (!ProfileImage) {
        return toast.error("Profile Image is required");
      }
    }
    if (!userData.phoneNumber) {
      return toast.error("Phone Number is required");
    }
    const userDetails = {
      ...userData,
      socialMediaLinks: socialMediaLinks,
    };
    axios
      .post(
        SERVER_URL + "/admin/api/addUser",
        { userDetails: userDetails },
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSocialMediaLinks([]);
          const userID = res.data.id;

          const uploadImage = async (image, endpoint) => {
            if (image) {
              const formData = new FormData();
              formData.append("image", image);
              formData.append("userID", userID); // Include userID separately
              try {
                const response = await axios.post(
                  SERVER_URL + endpoint,
                  formData,
                  {
                    headers: {
                      "x-access-token": localStorage.getItem("adminToken"),
                    },
                  }
                );

                if (response.status === 200) {
                  console.log(`${endpoint} image uploaded successfully.`);
                } else {
                  console.log(`${endpoint} image upload failed.`);
                }
              } catch (error) {
                console.log(`Error uploading ${endpoint} image:`, error);
              }
            }
          };

          const handleImageUploads = async () => {
            try {
              await uploadImage(logo, "/admin/api/logoImage");
              await uploadImage(cover, "/admin/api/coverImage");
              await uploadImage(ProfileImage, "/admin/api/profileImage");

              toast.success("User added successfully");
              setProfile(false);
              setUserData({
                name: "",
                username: "",
                email: "",
                phoneNumber: 0,
                mobileNo: 0,
                companyName: "",
                bio: "",
                designation: "",
                tapeasyCount: 0,
               
              });

              setLogo(null);
              setCover(null);
              setProfileImage(null);
              setLinkStore(false);
              setAlreadyExist(false);
              axios
                .get(SERVER_URL + "/admin/api/getUsers?page=" + pageCount, {
                  headers: {
                    "x-access-token": localStorage.getItem("adminToken"),
                  },
                })
                .then((res) => {
                  setAllUsers(res.data);
                  console.log(allUsers);
                })
                .catch((err) => {
                  console.log(err);
                });
            } catch (error) {
              console.log("Error handling image uploads:", error);
            }
          };

          handleImageUploads();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error adding user PhoneNumber May Already Exist");
      });
  }

  function handleAddNewUser() {
    setProfile(true);
    setLinkStore(false);
    setAlreadyExist(false);
    setUserData({
      name: "",
      username: "",
      email: "",
      phoneNumber: 0,
      companyName: "",
      bio: "",
      designation: "",
      mobileNo: 0,
      tapeasyCount: 0,
      isActive:true
    });
    setSocialMediaLinks([]);
    setLogo(null);
    setCover(null);
    setProfileImage(null);
  }
  function handleSignOut() {
    localStorage.removeItem("adminToken");
    navigate("/");
  }
  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <div className="admin-dashboard">
        <div className="side-nav">
          <div className="side-nav-top">
            <img src="./images/logo.png" alt="" />
          </div>
          <ul>
            <li
              onClick={() => {
                setProfile(false);
                setAlreadyExist(false);
              }}
            >
              All Users
            </li>
            <li onClick={handleAddNewUser}>Add User</li>
            {/* <li onClick={()=>navigate("/admin/create-admin")}>Create Admin</li> */}
            <li onClick={()=>navigate("/admin/reset-password")}>Reset Password</li>
            <li onClick={handleSignOut}>Sign Out</li>
          </ul>
        </div>
        <div className="main">
          {profile ? (
            <>
              <div className="main-top">
                <div className="main-top-left">
                  <h1>Admin Dashboard</h1>
                </div>
                <div className="main-top-right">
                  <button onClick={handleProfileCreationSubmit}>Done</button>
                </div>
              </div>
              <div className="main-buttons">
                <button onClick={() => setLinkStore(false)}>Profile</button>
                <button onClick={() => setLinkStore(true)}>Link Store</button>
              </div>
              <div className="main-content">
                {linkStore ? (
                  <AdminLinkStore
                    socialMediaLinks={socialMediaLinks}
                    setSocialMediaLinks={setSocialMediaLinks}
                  />
                ) : (
                  <AdminProfileCreation
                    setProfileImage={setProfileImage}
                    setCover={setCover}
                    setLogo={setLogo}
                    setUserData={setUserData}
                    userData={userData}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="main-top">
                <div className="main-top-left">
                  <h1>Admin Dashboard</h1>
                </div>
              </div>
              <div className="main-content">
                <AdminAllUsers
                  allUsers={allUsers}
                  setProfileImage={setProfileImage}
                  setCover={setCover}
                  setLogo={setLogo}
                  setUserData={setUserData}
                  setProfile={setProfile}
                  setSocialMediaLinks={setSocialMediaLinks}
                  setAllUsers={setAllUsers}
                  setPageCount={setPageCount}
                  setAlreadyExist={setAlreadyExist}
                  setIsActiveChanges={setIsActiveChanges}
                />
              </div>
              <div className="pagination">
                <button
                  className="page-button"
                  onClick={() => {
                    if (pageCount !== 1) {
                      setPageCount((prev) => prev - 1); // Increment pageCount
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className="page-button"
                  onClick={() => {
                    if (allUsers.length !== 0) {
                      setPageCount((prev) => prev + 1); // Increment pageCount
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
