import React from 'react';
import './admin-login.css';
import { useState } from 'react';
import axios from 'axios';
import SERVER_URL from '../../../config/serverURL';
import { useNavigate } from 'react-router-dom';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
function handleSubmit(e){
  e.preventDefault();
  axios.post(SERVER_URL+'/admin/api/login', {email, password}).then(res=>{
   if(res.status === 200){
    localStorage.setItem('adminToken',res.data.token);
    navigate('/admin')
   }
  
  }).catch((err)=>{
    console.log(err);
  })
}
  return (
    <>
      <div className="admin-login">
        <div className="admin-login__container">
          <div className="admin-login__container__header">
            <h1>Admin Login</h1>
          </div>
          <div className="admin-login__container__body">

              <div className="admin-login__container__body__form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" placeholder="Enter your email" onChange={(e)=>setEmail(e.target.value)}/>
              </div>
              <div className="admin-login__container__body__form-group">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" placeholder="Enter your password"  onChange={(e)=>setPassword(e.target.value)}/>
              </div>
              <p className="forgot-password" onClick={()=>navigate("/admin/otp-verify/"+email)}>
                Forgot Password? 
              </p>

              <div className="admin-login__container__body__form-group">
                <button type="submit" onClick={handleSubmit}>Login</button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
