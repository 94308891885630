import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import SERVER_URL from "../config/serverURL";
import UploadAvatar from "./UploadAvatar";
import base64ToFile from "../config/base64ToFile";
function BusinessProfile({
  setTab,
  businessDetails,
  setBusinessDetails,
  logo,
  setLogo,
  coverImage,
  setCoverImage,
  businessServerImages,
  setBusinessServerImages,
}) {
  const [crop, setCrop] = useState(false);
  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState(null);
  // Business Logic
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBusinessDetails((prev) => ({ ...prev, [name]: value }));
  };
  const handleLogoInputChange = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    setBusinessServerImages((prev) => ({
      ...prev,
      logo: URL.createObjectURL(file),
    }));
    setSrc(URL.createObjectURL(file));
    setPreview(URL.createObjectURL(file));
    setCrop(true);
  };
  const handleCoverInputChange = (e) => {
    const file = e.target.files[0];
    setCoverImage(file);
    setBusinessServerImages((prev) => ({
      ...prev,
      coverImage: URL.createObjectURL(file),
    }));
  };

  const updateBusiness = async () => {
    try {
      // Update business details
      if (!businessDetails.companyName) {
        toast.error("Please enter company name");
        return;
      }
      if (!businessDetails.designation) {
        toast.error("Please enter designation");
        return;
      }
      const response = await axios.post(SERVER_URL + "/api/editBusiness", {
        userID: localStorage.getItem("token"),
        userDetails: businessDetails,
      });

      if (response.status === 200) {
        // Handle cover image upload
        if (coverImage) {
          const coverFormData = new FormData();
          coverFormData.append("userID", localStorage.getItem("token"));
        
          // Check if the cover image size is greater than 20MB
          if (coverImage.size > 10 * 1024 * 1024) {
            toast.error("Image size should be less than 10MB");
            return;
          }
        
          coverFormData.append("image", coverImage);
        
          try {
            const coverResponse = await axios.post(
              SERVER_URL + "/api/addCoverImage",
              coverFormData
            );
        
            if (coverResponse.status !== 200) {
              toast.error("Error uploading cover image");
            } 
          } catch (error) {
            toast.error("Error uploading cover image");
            console.error("Error uploading cover image", error);
          }
        }
        

        // Handle logo upload
        const isURL = businessServerImages.logo ? (businessServerImages.logo.startsWith('http') || businessServerImages.logo.startsWith('https')):true;

       
        const logoFormData = new FormData();
        logoFormData.append("userID", localStorage.getItem("token"));
        if (!isURL) {
          if (!logo) {
            const file = base64ToFile(businessServerImages.logo, "image");
            if (file && file.size > 10 * 1024 * 1024){
              toast.error("Image size should be less than 10MB");
              return;
            }
            logoFormData.append("image", file);
          } else {
            if (logo && logo.size > 10 * 1024 * 1024){
              toast.error("Image size should be less than 10MB");
              return;
            }
            logoFormData.append("image", logo);
          }

          const logoResponse = await axios.post(
            SERVER_URL + "/api/editLogo",
            logoFormData
          );

          if (logoResponse.status !== 200) {
            toast.error("Try Another Image");
            throw new Error("Error uploading logo");
          }
        }
        toast.success("Business details updated successfully");
        setTab(true);
      } else {
        toast.error("Error updating business details");
        throw new Error("Error updating business details");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputFocus = (event) => {
    if (!event.target.value) {
      event.target.placeholder = "";
    }
  };
  const handleInputBlur = (event) => {
    if (!event.target.value) {
      event.target.placeholder = event.target.getAttribute("data-placeholder");
    }
  };
  function handleCropChange() {
    setCrop(false);
    if (!preview) return;
    setBusinessServerImages((prev) => ({
      ...prev,
      logo: preview,
    }));
    setLogo(null);
  }
  
  return (
    <div>
      <div className="business-section">
 
        <div
          className="business-cover-image"
          style={{
            backgroundImage:
              businessServerImages.coverImage &&
              `url(${businessServerImages.coverImage})`,
          }}
        >
          <label for="cover-image">
            <p> Cover Image</p>{" "}
            <img src="./images/camera-icon.png" alt="add cover" />
          </label>
          <input
            type="file"
            id="cover-image"
            onChange={handleCoverInputChange}
          />
        </div>
        <div className="business-details">
          <div className="business-profile-image">
            <label for="profile-image">
              <img
                src={
                  businessServerImages.logo
                    ? businessServerImages.logo
                    : "./images/profile-creation.svg"
                }
                alt=""
                className="logo"
              />
              <img
                src="./images/camera-icon.png"
                className="camera-icon"
                alt="add profile"
              />
            </label>

            <input
              type="file"
              id="profile-image"
              name="image"
              onChange={handleLogoInputChange}
            />
            <h5>Add Business Logo</h5>
          </div>
          <div className="business-form">
            <div>
              <input
                type="text"
                placeholder="Organization Name"
                name="companyName"
                value={businessDetails.companyName}
                onChange={handleChange}
                maxLength={20}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                data-placeholder="Organization Name"
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Business Designation"
                name="designation"
                value={businessDetails.designation}
                onChange={handleChange}
                maxLength={20}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                data-placeholder="Business Designation"
              />
            </div>
            <div>
              <button onClick={updateBusiness}>Done</button>
            </div>
          </div>
      
        </div>
      </div>
      {crop && (
        <div className="business-crop-container">
          <UploadAvatar
            preview={preview}
            setPreview={setPreview}
            src={src}
            setSrc={setSrc}
          />
          <div className="crop-buttons">
            <button className="crop-done" onClick={handleCropChange}>
              Done
            </button>
            <button className="crop-cancel" onClick={() => setCrop(false)}>
              cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessProfile;
