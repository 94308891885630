import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "./components/Home";
import SignIn from './components/SignIn';
import ProfileCreation from "./components/ProfileCreation";
import LinkStore from "./components/LinkStore";
import Settings from "./components/Settings";
import ProfilePreview from "./components/ProfilePreview";
import Insight from "./components/Insight";
import Share from "./components/Share";
import Mails from "./components/Mails";
import AdminLogin from "./components/Admin/AdminLogin/AdminLogin";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/App.css";
import AdminDashboard from "./components/Admin/AdminDashboard/AdminDashboard";
import AdminOTPVerify from "./components/Admin/AdminOTPVerify/AdminOTPVerify";
import AdminResetPassword from "./components/Admin/AdminResetPassword/AdminResetPassword";



function App() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const firstSegment = pathSegments[1];

  return (
    <div className={`container ${firstSegment === "admin" ? "admin":""} `}>
      <div className={`app ${firstSegment === "admin" ? "admin":""} ${firstSegment === "" ? "home-container":""}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/profile-creation" element={<ProfileCreation />} />
          <Route path="/link-store" element={<LinkStore />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/:userId" element={<ProfilePreview />} />
          <Route path="/insight" element={<Insight />} />
          <Route path="/share" element={<Share />} />
          <Route path="/mails" element={<Mails />} />
          
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/otp-verify/:email" element={<AdminOTPVerify/>} />
          <Route path="/admin/reset-password" element={<AdminResetPassword/>} />
         
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="*" element={<NotFoundRedirect />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
}
function NotFoundRedirect() {
  return <Navigate to="/" />;
}
export default App;
