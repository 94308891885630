import React, { useState, useEffect } from "react";
import TopNavbar from "./TopNavbar";
import "../styles/mails.css";
import axios from "axios";
import SERVER_URL from "../config/serverURL";
import { useNavigate } from "react-router-dom";
import protectedRoute from "./Protected";
import Loading from "./Loading";
//mail delete
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

function Mails() {
  const navigate = useNavigate();
  const [mails, setMails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupForm, setPopupForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [originalMails, setOriginalMails] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/sign-in");
      return;
    }

    const checkProtected = async () => {
      try {
        const isProtected = await protectedRoute();

        if (!isProtected) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
          try {
            setLoading(false);
            const res = await axios.get(
              `${SERVER_URL}/api/getAllMails?userID=${token}`
            );
            setOriginalMails(res.data); // Store the original mails
            setMails(res.data);
          } catch (err) {
            console.error("Error fetching mails:", err);
          }
        }
      } catch (error) {
        console.error("Error checking protected route:", error);
        navigate("/sign-in");
      }
    };

    checkProtected();
  }, [navigate]);

  const handleItemClick = (index) => {
    const filteredLinks = mails[index];
    setFormData(filteredLinks);
    setPopupForm(true);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setSearch(searchQuery);

    const filteredMails = originalMails.filter((mail) => {
      return mail.name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    setMails(filteredMails);
  };

  const handleBackspace = (event) => {
    if (event.key === "Backspace") {
      setSearch(""); // Reset search when Backspace is pressed
      setMails(originalMails); // Restore the original mails list
    }
  };
//Mail delete
  // const handleSingleDelete = (id) => {
  //   axios
  //     .delete(
  //       `${SERVER_URL}/api/deleteMail/${id}?userID=` +
  //         localStorage.getItem("token")
  //     )
  //     .then((res) => {
  //     setFormData((prev) => {
  //       return prev._id === id ? {} : prev;
  //     });
  //     setPopupForm(false);
  //     toast.success("Mail deleted successfully!");
  //     setMails((prev) => {
  //       return prev.filter((mail) => {
  //         return mail._id !== id;
  //       });
  //     });
  //   })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Failed to delete mail.");
  //     });
  // };


  // function handleDeleteAllMails() {
  //   confirmAlert({
  //     title: "Confirm Deletion",
  //     message: "Are you sure you want to delete all mails?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           axios
  //             .delete(
  //               `${SERVER_URL}/api/deleteAllMails?userID=` +
  //                 localStorage.getItem("token")
  //             )
  //             .then((res) => {
  //               console.log(res);
  //               console.log(res.data);
  //               toast.success("All mails deleted successfully!");
  //               setMails([]);
  //             })
  //             .catch((error) => {
  //               console.error(error);
  //               toast.error("Failed to delete mails.");
  //             });
  //         },
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {
  //           // Do nothing or add any specific action
  //         },
  //       },
  //     ],
  //   });
  // }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="mails-container">
        <TopNavbar active={"mails"} />
        <div className="mails-header">
          <div className="mails-search">
            <label htmlFor="search">
              <img src="./images/search.png" alt="search" />
            </label>
            <input
              type="text"
              value={search}
              placeholder="Search"
              id="search"
              onChange={handleSearch}
              onKeyDown={handleBackspace} // Handle Backspace key
            />
          </div>
          {/* <button className="clear-all-mails" onClick={handleDeleteAllMails}>
            Clear All
          </button> */}
        </div>

        <div className="mails-list">
          {mails.map((mail, index) => {
            return (
              <div
                className="mails-list-item"
                key={mail._id}
                onClick={() => handleItemClick(index)}
              >
                <div className="mails-list-left">
                  <div className="mails-list-img">
                    <img src="./images/user-icon.png" alt="" />
                  </div>
                  <h3>{mail.name}</h3>
                </div>
                <p>{mail.mobileNo}</p>
              </div>
            );
          })}
        </div>
        {popupForm && (
          <>
            <div className="mail-popup-container">
              <div className="mail-popup">
                <div className="mail-popup-header">
                  <img
                    src="./images/x.svg"
                    alt=""
                    onClick={() => setPopupForm(false)}
                  />
                </div>
                <div className="mail-popup-fields">
                  <div className="popup-field">
                    <p>Name : {formData.name}</p>
                  </div>
                  <div className="popup-field">
                    <p>Mobile : {formData.mobileNo}</p>
                  </div>
                  <div className="popup-field">
                    <p>Email : {formData.email}</p>
                  </div>
                  <div className="popup-field">
                    <p>Designation : {formData.designation}</p>
                  </div>
                  <div className="popup-field">
                    <p>Company : {formData.company}</p>
                  </div>
                  <div className="popup-field">
                    <p>Note : {formData.note}</p>
                  </div>
                  {/* <i
                    className="fas fa-trash"
                    onClick={() => handleSingleDelete(formData._id)}
                  /> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}

export default Mails;
