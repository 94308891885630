import React, { useEffect } from "react";
import "./AdminResetPassword.css";
import { useState } from "react";
import axios from "axios";
import SERVER_URL from "../../../config/serverURL";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../Loading";
function AdminResetPassword() {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [password, setPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if(!localStorage.getItem("adminToken")){
      navigate("/admin/login");
    }
    axios
      .get(SERVER_URL + "/admin/api/protected", {
        headers: { "x-access-token": localStorage.getItem("adminToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          
          setUser(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        navigate("/admin/login");
      });
  }, [navigate, user]);

  function handleSubmit(e) {
    e.preventDefault();

    axios
      .post(
        SERVER_URL + "/admin/api/changePassword",
        { password },
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Password Changed Successfully");
          navigate("/admin");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="admin-login">
        <div className="admin-login__container">
          <div className="admin-login__container__header">
            <h1>Reset Your Password</h1>
          </div>
          <div className="admin-login__container__body">
            <div className="admin-login__container__body__form-group">
              <label htmlFor="password">Enter Your New Password</label>
              <input
                type="text"
                id="password"
                placeholder="Enter Your Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="admin-login__container__body__form-group">
              <button type="submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminResetPassword;
