import React, { useEffect } from "react";
import "../styles/link-store.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LinkContainer from "./LinkContainer";
import axios from "axios";
import protectedRoute from "./Protected";
import SERVER_URL from "../config/serverURL";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";

function LinkStore() {
  const navigate = useNavigate();
  const [iconName, setIconName] = useState("phone");
  const [search, setSearch] = useState("");
  const [filteredLink, setFilteredLink] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [linkData, setLinkData] = useState({});
  const [values, setValues] = useState({
    phone: "",
    website: "",
    email: "",
    instagram: "",
    linkedin: "",
    facebook: "",
    behance: "",
    medium: "",
    spotify: "",
    youtube: "",
    vimeo: "",
    googleMap:"",
    whatsapp: ""
   
  });
  const placeholders = {
    website: "Enter Your URL",
    email: "Enter Your Email",
    instagram: "Enter Your URL",
    linkedin: "Enter Your URL",
    facebook: "Enter Your URL",
    behance: "Enter Your URL",
    medium: "Enter Your URL",
    spotify: "Enter Your URL",
    youtube: "Enter Your URL",
    vimeo: "Enter Your URL",
    googleMap:"Enter Your URL",
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/sign-in");
    }
    const checkProtected = async () => {
      try {
        const isProtected = await protectedRoute();

        if (!isProtected) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
          const response = await axios.get(SERVER_URL + "/api/getUser", {
            params: { userID: localStorage.getItem("token") },
          });

          if (response.status === 200) {
            if (response.data.username === "") {
              navigate("/profile-creation");
            }
           
            setValues((prev) => {
              const socialMediaLinks = response.data.socialMediaLinks;
              const updatedValues = {};

              socialMediaLinks.forEach((link) => {
                if (link.status && link.link) {
                  updatedValues[link.name] = link.link;
                }
              });

              return {
                ...prev,
                ...updatedValues,
              };
            });
            setLinkData(response.data.socialMediaLinks);
          } else {
            console.log("Error");
          }
        }
      } catch (error) {
        console.log("Error");
      }
    };

    checkProtected();
  }, [navigate]);

  const togglePopup = (name) => {
    setIconName(name);

    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSave = (iconName) => {
    setIsOpen(!isOpen);

    const userDetails = {
      [iconName]: values[iconName],
    };
    const existingLinkIndex = linkData.findIndex(
      (linkObj) =>
        linkObj.name === iconName && linkObj.link === values[iconName]
    );
    if (existingLinkIndex === -1) {
      // Handle saving the links or submit the form to a server
      axios
        .post(SERVER_URL + "/api/addLink", {
          userDetails,
          userID: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Link Added Successfully");
            setLinkData((prev) => [
              ...prev,
              { name: iconName, link: values[iconName], status: true },
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Call handleSearch whenever the search state changes
  useEffect(() => {
    const handleSearch = (searchValue) => {
      // Get all the keys from the `values` object
      const allKeys = Object.keys(values);

      // Filter the keys based on whether the search value exists within each key (ignoring case)
      const filteredKeys = allKeys.filter((key) =>
        key.toLowerCase().includes(searchValue.toLowerCase())
      );

      // Update the `filteredLink` state with the filtered keys
      setFilteredLink(filteredKeys);
    };

    handleSearch(search);
  }, [search, values]);

  return (
    <>
      <div className="link-store">
        <div className="link-store-container">
          <div className="back-button" onClick={() => navigate("/")}>
            <img src="./images/arrow.png" alt="back-button" />
          </div>
          <div className="link-store-container-title">
            <h2>Link Store</h2>
            <p>Add Link to your Profile</p>
          </div>
          <div className="link-store-search">
            <label htmlFor="search">
              <img src="./images/search.png" alt="search" />
            </label>
            <input
              type="text"
              placeholder="Search"
              id="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {isOpen && (
            <>
              <div className="popup">
                <div className="popup-heading">
                  <h2>
                    {iconName.charAt(0).toUpperCase() + iconName.slice(1)}
                  </h2>
                </div>
                <div className="popup-content">
                  <img src={`./social-icons/${iconName}.svg`} alt="icon" />
                </div>
                <div className="popup-action">
                  <div className="input-container">
                    <div className="input-wrapper">
                      {iconName === "phone" || iconName === "whatsapp" ? (
                        <PhoneInput
                          country={"in"}
                          placeholder="Enter Your Phone Number"
                          name={iconName}
                          onChange={(phone) =>
                            setValues((prevValues) => ({
                              ...prevValues,
                              [iconName]: phone, // Update the specific value based on iconName
                            }))
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          placeholder={placeholders[iconName]}
                          name={iconName}
                          onChange={handleChange}
                          className="input-field"
                        />
                      )}
                    </div>
                  </div>
                  <button onClick={() => handleSave(iconName)}>Done</button>
                </div>
              </div>
            </>
          )}
          {search !== "" && (
            <>
              {" "}
              <div className="container-link">
                <div className="link-store-items">
                  {filteredLink.map((link) => {
                    return (
                      <div className="item-card">
                        <div className="card-left">
                          <img src={`./social-icons/${link}.svg`} alt="icon" />
                          <h4>
                            {link.charAt(0).toUpperCase() + link.slice(1)}
                          </h4>
                        </div>
                        <div className="card-right">
                          <button onClick={() => togglePopup(link)}>Add</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          <LinkContainer togglePopup={togglePopup} values={values} />
        </div>

        <div className="done-container">
          <button className="done-button" onClick={() => navigate("/")}>
            Done
          </button>
        </div>
      </div>
    </>
  );
}

export default LinkStore;
