import React, { useEffect, useState } from "react";
import TopNavbar from "./TopNavbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import protectedRoute from "./Protected";
import "../styles/home.css";
import SERVER_URL from "../config/serverURL";
import Loading from "./Loading";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import BusinessProfile from "./BusinessProfile";

function Home() {
  const [tab, setTab] = useState(true);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingState, setEditingState] = useState({
    name: "",
    link: "",
    index: 0,
    _id: "",
  });
  const [userDetails, setUserDetails] = useState({
    name: "",
    username: "",
    bio: "",
    phoneNumber: "",
    email: "",
  });
  const [socialCard, setSocialCard] = useState({});
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [businessDetails, setBusinessDetails] = useState({
    companyName: "",
    designation: "",
  });
  const [coverImage, setCoverImage] = useState("");
  const [logo, setLogo] = useState("");
  const [businessServerImages, setBusinessServerImages] = useState({
    coverImage: "",
    logo: "",
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/sign-in");
    }
    const checkProtected = async () => {
      try {
        const isProtected = await protectedRoute();
        setIsLoading(false);
        if (!isProtected) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
          const response = await axios.get(SERVER_URL + "/api/getUser", {
            params: { userID: localStorage.getItem("token") },
          });
          if (response.status === 200) {
            if (response.data.username === "") {
              navigate("/profile-creation");
            }
            setUserDetails(response.data);
            setIsLoading(false);
            setBusinessDetails(response.data);
            localStorage.setItem("username", response.data.username);
            if (response.data.profileImage.filename !== "") {
              setImage(response.data.profileImage.filename);
            }
            if (response.data.logo.filename !== "") {
              setBusinessServerImages((prev) => ({
                ...prev,
                logo: `${SERVER_URL}/logo/${response.data.logo.filename}`,
              }));
            }
            if (response.data.coverImage.filename !== "") {
              setBusinessServerImages((prev) => ({
                ...prev,
                coverImage: `${SERVER_URL}/userCoverImage/${response.data.coverImage.filename}`,
              }));
            }

            // Your previous transformedLinks logic goes her
            setSocialCard(response.data.socialMediaLinks);
          } else {
            console.log("Error");
            localStorage.removeItem("token");
            navigate("/sign-in");
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        localStorage.removeItem("token");
        setIsLoading(false);
        navigate("/sign-in");
      }
    };

    checkProtected();
  }, [navigate, isLoading]);

  const checkHandle = (e, index) => {
    const newStatus = e.target.checked;
    setSocialCard((prevSocialCard) => {
      const newSocialCard = [...prevSocialCard];
      newSocialCard[index].status = newStatus;
      return newSocialCard;
    });
    axios.post(SERVER_URL + "/api/editStatus", {
      linkIndex: index,
      status: newStatus,
      userID: localStorage.getItem("token"),
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedLinks = Array.from(socialCard);
    const [movedLink] = reorderedLinks.splice(result.source.index, 1);
    reorderedLinks.splice(result.destination.index, 0, movedLink);

    setSocialCard(reorderedLinks);
    axios.post(SERVER_URL + "/api/reorderedLink", {
      reorderedLinks: reorderedLinks,
      userID: localStorage.getItem("token"),
    });
  };
  const handleIconClick = (item, index) => {
    setIsOpen(true);

    setEditingState({
      name: item.name,
      link: item.link,
      index: index,
      _id: item._id,
    });
  };
  const handleEdit = () => {
    //check link is valid or already exist in social card
    if (editingState.link === "") {
      toast.error("Link cannot be empty");
      return;
    }
    let existingIndex = socialCard.findIndex((item) => {
      return item.link === editingState.link;
    });
    if (existingIndex !== -1) {
      toast.error("Link already exist");
    } else {
      console.log(editingState);
      axios
        .post(SERVER_URL + "/api/editLink", {
          linkIndex: editingState.index,
          linkValue: editingState.link,
          userID: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.status === 200) {
            setIsOpen(false);
            setIsEditing(false);
            setSocialCard((prevSocialCard) => {
              const newSocialCard = [...prevSocialCard];
              newSocialCard[editingState.index].link = editingState.link;
              return newSocialCard;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleDelete = () => {
    axios
      .post(SERVER_URL + "/api/deleteLink", {
        linkIndex: editingState.index,
        userID: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.status === 200) {
          setIsOpen(false);
          setIsEditing(false);
          setSocialCard((prevSocialCard) => {
            const newSocialCard = [...prevSocialCard];
            newSocialCard.splice(editingState.index, 1);
            return newSocialCard;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setIsOpen(false);
  };
  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="home">
        <TopNavbar active={"home"} />
        <div className="home__tabs">
          <div className="home-tab-container">
            <div
              className={`personal ${tab && "active"}`}
              onClick={() => setTab(true)}
            >
              Personal
            </div>
            <div
              className={`business ${!tab && "active"}`}
              onClick={() => setTab(false)}
            >
              Business
            </div>
          </div>
        </div>
        {tab ? (
          <>
            <div className="user-card">
              <div className="user-card-left">
                <div className="user-card__image">
                  {image ? (
                    <img src={`${SERVER_URL}//userImage/${image}`} alt="" />
                  ) : (
                    <img src="./images/user-icon.png" alt="" />
                  )}
                </div>
                <div className="user-card-details">
                  <h4>Hey Welcome</h4>
                  <p>{userDetails.name}</p>
                </div>
              </div>
              <div
                className="user-card-right"
                onClick={() => navigate("/profile-creation")}
              >
                <img src="./images/edit-icon.svg" alt="" />
              </div>
            </div>
            <div className="add-button">
              <img
                src="./images/add.png"
                alt="add"
                onClick={() => navigate("/link-store")}
              />
            </div>

            <div className="container-link home">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="socialCardDroppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {Object.keys(socialCard).map((key, index) => {
                        const item = socialCard[key];

                        return (
                          <Draggable key={key} draggableId={key} index={index}>
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div className="link-store-items ">
                                  <div className="item-card home-list-item">
                                    <div className="card-left">
                                      <i
                                        {...provided.dragHandleProps}
                                        class="fa-solid fa-grip-vertical"
                                      ></i>
                                      <img
                                        src={`./social-icons/${item.name}.svg`}
                                        alt={item.name}
                                        onClick={() =>
                                          handleIconClick(item, index)
                                        }
                                      />
                                      <h4
                                        onClick={() =>
                                          handleIconClick(item, index)
                                        }
                                      >
                                        {item.name}
                                      </h4>
                                    </div>
                                    <div className="card-right">
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={item.status}
                                          onChange={(e) => checkHandle(e, key)}
                                        />
                                        <span className="slider"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {isOpen && (
              <div className="home-link-popup-container">
                {isEditing ? (
                  <>
                    <h3>{editingState.name}</h3>
                    <div>
                      <input
                        type="text"
                        value={editingState.link}
                        onChange={(e) =>
                          setEditingState((prev) => ({
                            ...prev,
                            link: e.target.value,
                          }))
                        }
                        className="edit-input-home"
                      />
                    </div>
                    <button onClick={handleEdit}>Done</button>
                  </>
                ) : (
                  <>
                    <div
                      className="home-link-popup-content edit"
                      onClick={() => setIsEditing(true)}
                    >
                      <p>Edit</p>
                    </div>
                    <div
                      className="home-link-popup-content delete"
                      onClick={handleDelete}
                    >
                      <p>Delete</p>
                    </div>
                  </>
                )}
                <div
                  className="home-link-popup-content cancel"
                  onClick={() => {
                    setIsOpen(false);
                    setIsEditing(false);
                  }}
                >
                  <p>Cancel</p>
                </div>
              </div>
            )}
          </>
        ) : (
          <BusinessProfile
            setTab={setTab}
            businessDetails={businessDetails}
            setBusinessDetails={setBusinessDetails}
            logo={logo}
            setLogo={setLogo}
            coverImage={coverImage}
            setCoverImage={setCoverImage}
            businessServerImages={businessServerImages}
            setBusinessServerImages={setBusinessServerImages}
          />
        )}
      </div>
    </>
  );
}

export default Home;
