import React from "react";

function AdminProfileCreation({
  setUserData,
  userData,
  setLogo,
  setCover,
  setProfileImage,
}) {
  const handleInputChange = (field, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [field]: value,
    }));
  };

  return (
    <>
      <div className="admin-profile">
        <div className="profile-top">
          <div className="profile-left">
            <div className="profile-field">
              <p>Name</p>
              <input
                type="text"
                value={userData.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </div>
            <div className="profile-field">
              <p>Username</p>
              <input
                type="text"
                value={userData.username}
                onChange={(e) => handleInputChange("username", e.target.value)}
                maxLength={25}
              />
            </div>
          </div>
          <div className="profile-right">
            <div className="profile-field">
              <p>Email</p>
              <input
                type="email"
                value={userData.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </div>
            <div className="profile-field">
              <p>Phone Number</p>
              <input
                type="number"
                value={userData.mobileNo}
                onChange={(e) => {
                  handleInputChange("mobileNo", e.target.value);
                  handleInputChange("phoneNumber", e.target.value);
                }}
                maxLength={20}
              />
            </div>
          </div>
        </div>
        <div className="profile-bottom">
          <div className="profile-textarea">
            <p>Bio</p>
            <textarea
              name="bio"
              id=""
              cols="30"
              rows="10"
              value={userData.bio}
              onChange={(e) => handleInputChange("bio", e.target.value)}
              maxLength={200}
            ></textarea>
          </div>
          <div className="profile-image">
            <p>Profile Image</p>
            <input
              type="file"
              onChange={(e) => setProfileImage(e.target.files[0])}
            />
          </div>
        </div>
      </div>

      <div className="business">
        <h1>Business</h1>
        <div className="business-profile">
          <div className="business-left">
            <div className="business-field">
              <p>Company Name</p>
              <input
                type="text"
                value={userData.companyName}
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
                maxLength={100}
              />
            </div>
            <div className="business-field">
              <p>Designation Name</p>
              <input
                type="text"
                value={userData.designation}
                onChange={(e) =>
                  handleInputChange("designation", e.target.value)
                }
                maxLength={100}
              />
            </div>
          </div>
          <div className="business-right">
            <div className="business-field">
              <p>Cover Image</p>
              <input
                type="file"
                onChange={(e) => setCover(e.target.files[0])}
              />
            </div>
            <div className="business-field">
              <p>Logo</p>
              <input type="file" onChange={(e) => setLogo(e.target.files[0])} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminProfileCreation;
