import React, { useState } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import "../styles/sharePopup.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

function SharePopup({ url, title, setShareButton }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = () => {
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 1500); // Reset success message after 1.5 seconds
  };
  return (
    <div className="share-popup-container">
      <div className="share-popup-header">
        <h3>Share Link</h3>
        <i
          class="fa-solid fa-xmark"
          onClick={() => setShareButton((prev) => !prev)}
        ></i>
      </div>
      <div className="share-popup-icons">
        <FacebookShareButton url={url} quote={title}>
          <img src="./social-icons/facebook.svg" alt="Facebook" />
        </FacebookShareButton>
        <EmailShareButton url={url} subject={title}>
          <img src="./social-icons/email.svg" alt="Email" />
        </EmailShareButton>

        <WhatsappShareButton url={url} title={title}>
          <img src="./social-icons/whatsapp.svg" alt="WhatsApp" />
        </WhatsappShareButton>
        <LinkedinShareButton url={url} title={title}>
          <img src="./social-icons/linkedin.svg" alt="LinkedIn" />
        </LinkedinShareButton>

        <PinterestShareButton url={url} media={url} description={title}>
          <img src="./social-icons/pinterest.svg" alt="Pinterest" />
        </PinterestShareButton>

        <RedditShareButton url={url} title={title}>
          <img src="./social-icons/reddit.svg" alt="Reddit" />
        </RedditShareButton>
      </div>
      <div className="share-popup-copy-link-container">
        <div className="share-popup-copy-link">
          <p>{url}</p>

          <CopyToClipboard text={url} onCopy={handleCopyClick}>
            {copySuccess ? (
              <img src="./images/double-tick.png" alt="copy" />
            ) : (
              <img src="./images/copy.svg" alt="copy" />
            )}
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default SharePopup;
