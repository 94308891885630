import React, { useEffect, useState } from "react";
import TopNavbar from "./TopNavbar";
import "../styles/insight.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import protectedRoute from "./Protected";
import SERVER_URL from "../config/serverURL";
import Loading from "./Loading";

function Insight() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [tapEasyCount, setTapEasyCount] = useState(0);
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
const [socialTapCount, setSocialTapCount] = useState(0);

  
  const [image, setImage] = useState("");

  useEffect(() => {
    if(!localStorage.getItem("token")){
      localStorage.removeItem("token");
      navigate("/sign-in");
    }
    const checkProtected = async () => {
      try {
        const isProtected = await protectedRoute();

        if (!isProtected) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
          const response = await axios.get(SERVER_URL+"/api/getUser", {
            params: { userID: localStorage.getItem("token") },
          });
          setIsLoading(false);

          if (response.status === 200) {
            if (response.data.username === "") {
              navigate("/profile-creation");
            }

    
            setTapEasyCount(response.data.tapeasyCount);
            const totalClickCount = response.data.socialMediaLinks.reduce((total, linkObj) => total + linkObj.count, 0);
        
            setSocialTapCount(totalClickCount);
            setSocialMediaLinks(response.data.socialMediaLinks);
            setImage(response.data.profileImage.filename);
          } else {
            navigate("/sign-in")
          }
        }
      } catch (error) {
        navigate("/sign-in")
      }
    };

    checkProtected();
  }, [navigate]);




  if (isLoading) {
    return <>

    <Loading />

    </>;
  }
  return (
    <>
      <div className="insight">
        <div className="Insight-container">
          <TopNavbar active={"insight"} />
          <div className="insight-profile">
            <div className="insight-profile-img">
              {image ? (
                <img src={`${SERVER_URL}//userImage/${image}`} alt="user" />
              ) : (
                <img src="./images/profile-creation.svg" alt="" />
              )}
            </div>
            <div className="insight-profile-content">
              <h2>Tap Streak {tapEasyCount}</h2>
              <p>Your’ve tapped {tapEasyCount} consecutive day</p>
            </div>
          </div>
          <div className="your-insight">
            <h2>Your Insight</h2>
            <div className="insight-cards">
              <div className="insight-card-content">
                <div className="info-icon">
                  <img src="./images/info-icon.svg" alt="" />
                </div>
                <h1>{tapEasyCount}</h1>
                <h4>TapEasy</h4>
              </div>
              <div className="insight-card-content">
                <div className="info-icon">
                  <img src="./images/info-icon.svg" alt="" />
                </div>
                <h1>{socialTapCount}</h1>
                <h4>Connections Tap</h4>
              </div>
            </div>
          </div>

          <div className="link-engagement">
            <h2>Link Engagement</h2>
            <div className="link-engagement-cards">
              {socialMediaLinks.map((item) => (
                <div className="link-engagement-card-content" key={item._id}>
                  <div className="link-engagement-card-left">
                    <div className="link-engagement-icon">
                      <img
                        src={`./social-icons/${item.name}.svg`}
                        alt={`${item.name} icon`}
                      />
                    </div>
                    <div className="link-engagement-details">
                      <h4>{item.name}</h4>
                      <p>{item.link}</p>
                    </div>
                  </div>
                  <div className="link-engagement-card-right">
                    <h4>{item.count} Taps</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default Insight;
