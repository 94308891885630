import axios from 'axios';
import SERVER_URL from '../config/serverURL';

const protectedRoute = async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    console.log('No token provided');
    return false;
  }

  try {
    const headers = {
      'Content-Type': 'application/json',
      'x-access-token': token,
    };
    const res = await axios.get(SERVER_URL+'/api/protected', { headers });

    if (res.status === 200) {
      // Proceed with rendering the protected content or do whatever you need
      localStorage.setItem('userId', res.data.user._id);
      return true;
    } else {
      console.log('Unauthorized');
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export default protectedRoute;
